// Navigation mobile
function nav_mobile() {
  $('#nav-mobile-trigger').on('click', function() {
    $('body').toggleClass('menu-open');
    $('#header .col-right').toggle();
  });
}

// Navigation size
function nav_size() {
  var position = $(window).scrollTop();
  if (position > 220) {
    $('body').addClass('header-small');
  } else {
    $('body').removeClass('header-small');
  }
  $(window).scroll(function() {
    if ($(this).scrollTop() > 220) {
      $('body').addClass('header-small');
    } else {
      $('body').removeClass('header-small');
    }
  });
}

// Navigation sub menu
function nav_sub_menu() {
  var viewport = $(window).width();
  if (viewport < 576) {
    $('#nav-main li[data-navsub] > a').on('click', function(event) {
      $(this).next().toggle();
      return false;
    });
  } else {
    $('#nav-main li[data-navsub] > a').on('click', function(event) {
      var navSub = $(this).parent();
      var isFocus = navSub.hasClass('focus');
      $('#nav-main li[data-navsub]').removeClass('focus');
      if (!isFocus) {
        navSub.addClass('focus');
      }
      event.stopPropagation();
      return false;
    });
    $('html').click(function() {
      $('#nav-main li[data-navsub]').removeClass('focus');
    });
  }
}

// Scroll top button
function scroll_top() {
  $('#btn-back-top').fadeOut(0);
  $(window).scroll(function() {
    if ($(this).scrollTop() > 220) {
      $('#btn-back-top').fadeIn(300);
    } else {
      $('#btn-back-top').fadeOut(300);
    }
  });
  $('#btn-back-top').on('click', function() {
    $('html, body').animate({
      scrollTop: 0
    }, '500');
    return false;
  });
}

function reservation_toggle() {
  var form = $('.form-resa-inner-page');
  var btn = $('#trigger-resa-page');
  form.hide();
  btn.on('click', function(){
    btn.parent().hide();
    form.show();
    return false;
  });
}

function reservation_toggle_bis() {
    var hash = window.location.hash;
    var form = $('.form-resa-inner-page');
    var btn = $('#trigger-resa-nav');
    form.hide();
    btn.on('click', function(){
        form.toggle();
        btn.toggleClass('open');
        /*parent.addClass('opened')*/;
        return false;
    });

    if (hash === '#resa') {
        btn.trigger('click');
    }
}

function f_resa_step_2() {
  var selectStep2 = $('.resa-step-2 .item .selectpicker-wp');
  selectStep2.addClass('inactive');
  var checkboxStep2 = $('.resa-step-2 .item .checkboxradio-wp input');
  checkboxStep2.on('change',function(){
    $(this).parents().eq(1).next().toggleClass('inactive');
  });
  $('.resa-step-2 .item.select-active .checkboxradio-wp input').trigger('click');
}

function f_paymentRadio() {
  $('.payment-planning').hide();
  $('#payment-radio input').on('change', function() {
    const checked = $('#payment-radio input[type=radio]:checked').val();
    if(checked === 'payment-2'){
      $('.payment-planning').show();
    }else {
      $('.payment-planning').hide();
    }
  });
}

function nav_form_resa_blink() {
    $('.resa-anchor').on('click', function () {
        $('#trigger-resa-page').trigger('click');
        $('#resa').fadeOut(500).fadeIn(500).fadeOut(500).fadeIn(500).find('#datepicker-start').datepicker("show");
    });
}

// Initialisation
reservation_toggle();
nav_mobile();
nav_sub_menu();
nav_size();
scroll_top();
reservation_toggle_bis();
f_resa_step_2();
f_paymentRadio();
nav_form_resa_blink();
